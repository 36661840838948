.center-position {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.login-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
input{
margin: 10px;
padding: 10px;
border-radius: 10px;
border: 1px solid gray;
width: 80%;
}
button{
margin: 10px;
padding: 10px 40px;
color: white;
background-color: #c76666;
border-radius: 20px;
border: none;
}
button:hover, button:active{
background-color: #dd5151;
cursor: pointer;
}
#or{
text-align: center;
margin: 10px 0px;
}
#alt-google-login{
display:none;
}