.background {
    min-width: 900px;
}
.loading-progress {
    width: 300;
    position: 'absolute'; 
    left: '45%'; 
    top: '48%';
}
.logout{
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}
.logout svg{
    cursor: pointer;
}
.logout svg:active{
    color: lightgreen;
}