.date {
    border-radius: 4px;
    width: 8vw;
    height: 4vh;
    border: solid 1px lightgray;
    padding: 1%;
    font-family:'Times New Roman', Times, serif;
    font-size: large;
}

.time {
    border-radius: 4px;
    width: 6vw;
    height: 4vh;
    border: solid 1px lightgray;
    padding: 1%;
    font-family:'Times New Roman', Times, serif;
    font-size: large;
    margin-left: 10%;
}