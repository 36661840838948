.description {
    width: '100vw';
    border-color: lightgray;
    border-radius: 4px;
    padding: 20px;
}

input[type="file"]::file-selector-button {
    padding: 0.2em 0.4em;
    border-radius: 4px;
    transition: 1s;
    width: 60%;
    height: 2.7em;
    border: solid 1px lightgray;
  }
  
  input[type="file"]::file-selector-button:hover {
    background-color: #a21024;
    border: 2px solid #a21024;
    color: white;
  }

  .number-class {
  -webkit-appearance: none;
  margin: 0;
  }

  ::-webkit-input-placeholder { /* WebKit browsers */
    color:    black;
     opacity: 1 !important;
}

.MuiBox-root {
  padding: 0px;
}